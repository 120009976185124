import * as React from "react"

const Hero = () => {
    return(
        <>
            <div className="hero-wrapper">
                <section className="hero-text">
                    <h2>Developer.</h2>
                    <h2>Designer.</h2>
                    <h2>Creative Technologist.</h2>
                    <h1>Shane Ducksbury.</h1>
                </section>
                <div>
                    <img src="./src/images/github-icon.svg" alt="" />
                </div>
            </div>
        </>
    )
}

export default Hero;